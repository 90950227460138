<template>
  <div class="container">
    <h1>Litely Medical Disclaimer</h1>
    <p>DISCLAIMER: THIS APP AND WEBSITE DO NOT PROVIDE MEDICAL ADVICE</p>
    <p>
      The company is not a licensed medical care provider. The app and website
      are not intended to replace professional medical advice or diagnose, treat
      or manage any illness or medical condition. No physician-patient
      relationship is created by this app/website or its use.
    </p>
    <p>
      Although we do our best to provide reliable and informative material, we
      cannot and do not make any representations or warranty with respect to the
      content on our site and app. Use of our site, app, and other services is
      at your own risk. Inform your physician of any changes you may make to
      your lifestyle.
    </p>
    <p>
      The information, including but not limited to, text, graphics, images and
      other material contained on this app and website are for informational
      purposes only. No material on this app and website is intended to be a
      substitute for professional medical advice, diagnosis, or treatment.
      Always seek the advice of your physician or other qualified healthcare
      providers with any questions you may have regarding a medical condition or
      treatment and before undertaking a new health care regimen, and never
      disregard professional medical advice or delay in seeking it because of
      something you have read on this website, app or our other communication
      channels.
    </p>
    <p>You should not do intermittent fasting:</p>
    <p>-If you are a child under 18.</p>
    <p>-If you are pregnant.</p>
    <p>-If you are breastfeeding.</p>
    <p>-If you have an eating disorder.</p>
    <p>-If you have health conditions.</p>
    <p>-If you take prescription medication.</p>
    <p>-If you are underweight (BMI &lt; 18.5).</p>
    <p>
      If you think you have a medical emergency, call 911 or go to the nearest
      open emergency room immediately.
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    let type = this.$route.params.type;
    if (type === "app") {
      this.$store.commit("setIsApp", true);
    } else {
      this.$store.commit("setIsApp", false);
    }
  },
  mounted() {},
  components: {},
  computed: {},
  methods: {},
};
</script>
<style lang="less" scoped>
@import "@/css/less_custom.less";

.container {
  width: 70%;
  height: 100%;
  // background-color: rgba(248, 248, 248, 1);
  margin: 0 auto;
  margin-bottom: 100px;
  margin-top: 60px;

  p {
    text-align: left !important;
    margin-top: 7px;
  }

  h1 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  h2,
  h3 {
    margin-top: 10px;
    text-align: left;
  }

  h4,
  h5 {
    text-align: left;
  }
}

@media screen and (max-width: @MobileMaxWidth) {
  .container {
    width: 90%;
  }
}
</style>
